<template>
  <div style="padding: 70px 0;text-align: center;">
    <div class="sctp-pad-tb15 fz-14">确认已经收到服务商提供的服务</div>
    <div class="sctp-mar-t15">
      <el-button @click="confirmService" type="primary" size="small">确认</el-button>
    </div>
  </div>
</template>

<script>
import {service} from "@/apis/index";

export default {
  name: "confirmService",
  data() {
    return {
      orderId: null,
      orderInfo: null,
    }
  },
  methods: {
    orderDetail() {
      service.orderDetail({
        orderId: this.orderId
      }).then(res => {
        let {data} = res;
        this.orderInfo = data;
      });
    },
    confirmService() {
      this.$confirm('确认已经完成该服务?', '提示').then(() => {
        service.confirmService({
          orderId: this.orderId,
          userId: this.user.userId
        }).then(res => {
          this.goPage({
            path: '/service/buy/pay',
            query: {
              orderId: this.orderId,
            }
          })
        });
      });
    }
  },
  beforeMount() {
    let {query: {orderId}} = this.$route;
    if (orderId) {
      this.orderId = orderId
      this.orderDetail();
    } else {
      this.goPage('/');
    }
  }
}
</script>

<style scoped>

</style>
